.menu__icon{
    height: 10px;
}





.menu_link{
    margin-top: 15px;
    padding: 10px 10px;
    color: rgb(97, 97, 97);;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    
  }
  
  .active__menu{
    font-weight: 500;
    background: rgb(237, 231, 246);
    color: rgb(94, 53, 177) ;
  }


  .menu_link:hover{
    font-weight: 500;
    background: rgb(237, 231, 246);
    color: rgb(94, 53, 177) ;
  }
