
body {
  margin: 0;
    font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* white-space: nowrap; */
}

.active{
  font-weight: 500 !important;
  background: rgb(237, 231, 246);
  color: rgb(94, 53, 177) !important;
}


.card__header{
  background-color: #F0F3F5;
  flex-wrap: wrap;
  gap: 7px;
}

.content__body{
  background-color: rgb(227, 242, 253);
    width: 100%;
    /* min-height: calc(100vh - 88px); */
    min-height: 100%;
    flex-grow: 1;
    padding: 20px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 8px;
    transition: margin 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}



.light__bg{
  background: rgb(237, 231, 246);
  color: rgb(94, 53, 177);
}


.purple_text{
  color: rgb(94, 53, 177);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form__btn__bg{
  background: rgb(94, 53, 177);
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* react quill editor height */
.ql-editor {
  min-height: 200px;
}

ul.pagination {
  display: flex;
}


.styles_WrapperUi__2TxXl {
  height: 161vh;
}

.ball-spin-clockwise-min_la-ball-spin-clockwise__3JQxN.ball-spin-clockwise-min_la-2x__2-m7c {
  width: 64px;
  height: 64px;
  margin-top: -156px;
}

/* .hide-show{
  display: none;
} */

.attributes-th .hide-show :hover{
  display: block !important;
}

.hide-show :hover{
  display: none !important;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}

.popub__bg{
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.301);
  width: 100%;
  height: 100vh;
}