.button__bg {
    background: rgb(149 78 255);
    color: white;
}

.gallery__image{
    width: 80px;
    height: 50px;
    object-fit: cover;
}

input.shop__checkbox{
    width: 20px;
    height: 30px;
    cursor: pointer;
}

.white-space{
      white-space: nowrap;

}